.about-page{
    padding: 1.14rem 0 0 3.24rem;
    .about-page-l{
        width: 5.05rem;
        height: 6.47rem;
        background: url("~@/assets/images/textBorder.png") no-repeat;
        background-size: 100% 100%;
        p{
            padding: 1.25rem 0.57rem 0 0.25rem;
            font-size: 0.18rem;
            color: #F89E02;
            word-spacing: 1px;
        }
    }
    .about-page-r{
        img{
            width: 8.06rem;
            // position: fixed;
            // bottom: 0;
            // right: 0;
        }
    }
}

@media screen and (max-width: 768px){
    .about-page{
        padding: 0 24px;
        .about-page-l{
            width: 90%;
            height: auto;
            margin-top: 20px;
            p{
                padding: 60px 20px; 
                font-size: 16px;
                color: #F89E02;
                margin-bottom: 0;
            }
        }
        .about-page-r{
            img{
                width: 200px;
            }
        }
    }
    
}