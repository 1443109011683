//搜索框下拉

.ant-select-item-option-content {
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 300 !important;
}
.ant-select-item{
    border-bottom: 1px solid #ccc !important;
}
.ant-select-item:last-child{
    border-bottom: none !important;
}
.ant-select-dropdown{
    border-radius: 0 !important;
}
.ant-select-item-option-active:hover{
   background-color: #bbb !important;
}
//手机端选择弹框
.activeshow:active{
    background-color: #d9d9d9 !important;
   
}

    .gallary-page {
        padding: 0 3.24rem 0;
        display: flex;
        justify-content: space-between;

        .filter {
            font-size: 0.2rem;
            font-weight: bold;
            color: #FFFFFF;
        }

        .gallary-filter-title {
            cursor: pointer;
            padding: 0.11rem 0;
            border-bottom: 1px solid rgba(255, 255, 255, .6);

            div {
                img {
                    width: 0.2rem;
                }

                span {
                    font-size: 0.15rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    vertical-align: middle;
                    transform: scale(0.8);
                    display: inline-block;
                    width: 2.7rem;
                    margin-left: -0.15rem;
                }
            }

            .arrow {
                width: 0.15rem;
            }
        }

        >.Horizontal_Layout {
            align-items: flex-start;
        }

        .gallary-title {
            padding: 0.1rem 0;

            // border-bottom: 1px solid rgba(255,255,255, .6);
            .filter {
                font-size: 0.2rem;
                font-weight: bold;
                color: #FFFFFF;
            }

            .tab-title {
                cursor: pointer;
                display: inline-block;

                img {
                    width: 1.18rem;
                }
            }

            .tab-title:first-child {
                margin-right: 0.5rem;
            }

            .tab-title.active {}
        }

        .selectlist {

            width: 100%;
            font-size: 0.15rem;
            border: 1px solid #F56702;
            border-radius: 0.05rem;
            background-color: rgba(0, 0, 0, 0);
            margin-bottom: 0.3rem;

            .ant-select-selection-item {
                font-size: 12px;
                color: #ccc;
            }

            .anticon-down {
                color: #ccc;
                font-size: 12px;
            }

            .ant-input {
                outline: none !important;
                color: #ccc !important;
                background-color: rgba(0, 0, 0, 0);
            }

            .ant-select-selector {
                background-color: rgba(0, 0, 0, 0) !important;
                border: none !important;
            }

            .ant-input-wrapper {
                background-color: rgba(0, 0, 0, 0);
            }

            .ant-input-affix-wrapper {
                outline: none !important;
                background-color: rgba(0, 0, 0, 0);
                border: none;
                color: #ccc;
            }

            .ant-input-group-addon {
                background-color: rgba(0, 0, 0, 0);
            }

            .ant-btn {
                background-color: rgba(0, 0, 0, 0);
                border: none;
            }

            .anticon-search {

                outline: none !important;
                color: #ccc;
            }
           
            // .ant-select-item-option-content {
              
            //     color: #000 !important;
            //     font-size: 0.15rem !important;
            // }

        }



        .gallary-page-l {
            width: 25.5%;
            padding: 0 0 0.2rem;
            height: 79vh;


            ul.checkBox {
                height: 60vh;
                overflow-y: scroll;

                li {
                    padding: 0.03rem 0;

                    .gallary-page-l-check {
                        display: none;

                        .ant-checkbox-wrapper {
                            width: 100%;
                            align-items: baseline;
                            justify-content: flex-start;
                            display: flex;
                            text-align: left;
                            margin-inline-start: 0;
                            margin-bottom: 0.1rem;
                        }
                     
                        .ant-checkbox+span {
                            font-size: 0.13rem;
                            font-weight: 300;
                            color: #FFFFFF;
                            vertical-align: middle;
                            transform: scale(0.8);
                            display: inline-block;
                            width: 100%;
                            margin-left: -0.15rem;
                            padding: 0;
                       
                        }
                     
                    }

                    .gallary-page-l-check.active {
                        display: block;
                        margin-top: 0.1rem;
                    }
                }

                li:last-child .gallary-filter-title {
                    border-bottom: 0;
                }
            }

            .reset-btn {
                text-align: center;
                padding: 0.12rem 0;
                color: #ffffff;
                font-size: 0.14rem;
                border: 1px solid rgba(255, 255, 255, .6);
                cursor: pointer;
            }

            .tag-select {
                margin-top: 0.5rem;

                .gallary-filter-title {
                    border-bottom: 0;
                }

                .tag-select-radio {
                    width: 100%;

                    // 覆盖radio样式
                    .ant-radio-wrapper {
                        position: relative;
                        width: 100%;

                        .ant-radio {
                            position: absolute;
                            right: 0;
                        }
                    }

                    .ant-space {
                        width: 100%;
                        display: block;
                        border-bottom: 1px solid #ffffff;
                    }

                    .ant-space-item {
                        border-top: 1px solid #ffffff;
                        border-left: 1px solid #ffffff;
                        border-right: 1px solid #ffffff;
                        padding: 0.05rem 0.2rem 0.05rem 0;
                    }

                    .ant-radio-wrapper span.ant-radio+* {
                        font-size: 0.12rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        vertical-align: middle;
                        transform: scale(0.8);
                        display: inline-block;
                        width: 100%;
                        margin-left: -0.15rem;
                        padding: 0;
                    }

                    .ant-radio-wrapper .ant-radio-inner {
                        background-color: transparent;
                        border-color: #ffffff;
                    }
                }

                .tag-select-radio.hide {
                    display: none;
                }
            }
        }

        .gallary-page-l::-webkit-scrollbar,
        .gallary-page-r::-webkit-scrollbar {
            width: 0 !important
        }

        // IE 10+
        .gallary-page-l,
        .gallary-page-r {
            -ms-overflow-style: none;
        }

        // Firefox
        .gallary-page-l,
        .gallary-page-r {
            overflow: -moz-scrollbars-none;
        }

        .gallary-page-r {
            width: 73%;
            height: 79vh;
            // overflow-y: scroll;
            position: relative;

            .gallary-page-r-banner {
                width: 98%;
                margin: 0 0.04rem 0.1rem 0.04rem;
                // position: sticky;
                // top: 0;
            }

            .tab-content {
                display: block;

                // overflow-y: scroll;
                .tab-content-imgBox {
                    //  display:inline;
                    display: flex;

                    flex-wrap: wrap;

                    .tab-content-imgBox-web {

                        width: 24%;
                        margin-right: 0.09rem;
                        margin-bottom: 0.05rem;

                        img {
                            // border-radius:0 0 0.1rem 0.1rem;
                            width: 100%;
                            //  padding: 0.04rem;
                        }

                    }

                    .tab-content-imgBox-web:hover {
                        padding: 0rem !important;
                    }

                    .tab-content-box {
                        background-color: #ccc;
                        border-radius: 0.05rem;

                        p {
                            font-size: 12px;

                            padding: 12px 0 12px 20px;
                        }
                    }

                    .tab-content-imgBox-app {

                        width: 48%;
                        margin-right: 0.08rem;
                        margin-bottom: 0.05rem;

                        img {
                            border-radius: 0.3rem;
                            width: 100%;
                            padding: 0.04rem;
                        }

                    }

                    .ImageBoxView {
                        border-radius: 0.05rem 0.05rem 0 0;
                        width: 100%;
                        // padding: 0.04rem;
                    }

                    // .tab-content-imgBox-web:hover{

                    //     padding: 0;
                    //     border-radius:0.05rem;

                    // }
                    .tab-content-imgBox-web:hover .tab-content-imgBox-change {

                        display: flex;
                        justify-content: space-between;
                    }

                    .tab-content-imgBox-no {

                        margin: 1rem 0 0 2rem;
                        font-size: 0.3rem;

                        color: #FFFFFF;
                    }

                    .tab-content-imgBox-change {
                        // opacity: 0;
                        display: none;
                        z-index: 100;
                        position: absolute;
                        right: 0%;
                        top: 0%;
                        width: 100%;

                        ul {
                            width: 100%;
                            //margin-left: 0.1rem;
                            background: rgba(0, 0, 0, 0.3);

                            // margin-right: 0.005rem;
                            li {
                                width: 100%;
                                padding: 0.1rem 0.1rem;
                                color: #FFFFFF;
                                font-size: 0.13rem;
                                line-height: 0.15rem;
                            }

                            li:hover {
                                cursor: pointer;
                                background: #bbbbbb;

                            }
                        }
                    }
                }
            }

            .tab-content.active {
                display: block;
            }
        }
    }

    .gallary-page-l-check {

        // 覆盖checkbox样式
        .ant-checkbox-inner {
            background-color: transparent;
        }

        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-checked:hover .ant-checkbox-inner {
            border-color: #F56702;
            background-color: #F56702;
        }

        .ant-checkbox:hover .ant-checkbox-inner {
            border-color: #F56702;
        }

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
            border-color: #F56702;
        }

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
            background-color: #F56702;
        }

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
            border-color: #F56702;
        }

        .ant-checkbox-checked:after {
            border: 2px solid #F56702;
        }
    }

    // ant 4.24.7
    .ant-checkbox .ant-checkbox-inner {
        background-color: transparent;
        border: 1px solid #ffffff;
    }

    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #F56702;
        border: 1px solid #F56702;
    }


    .no-more {
        font-size: 0.2rem;
        color: #bbbbbb;
        text-align: center;
        padding: 0.1rem 0;
    }

    .no-more.hide {
        display: none;
    }

    .loading {
        height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 47%;
    }

    .loading.hide {
        display: none;
    }

    .item {
        height: 20px;
        width: 2px;
        background: white;
        margin: 0px 3px;
        border-radius: 10px;
        animation: loading 1s infinite;
    }

    @keyframes loading {
        0% {
            height: 0px;
        }

        50% {
            height: 20px;
        }

        100% {
            height: 0px;
        }
    }

    /* 为每一个竖条设置延时 */
    .item:nth-child(2) {
        animation-delay: 0.1s;
    }

    .item:nth-child(3) {
        animation-delay: 0.2s;
    }

    .item:nth-child(4) {
        animation-delay: 0.3s;
    }

    .item:nth-child(5) {
        animation-delay: 0.4s;
    }

    .item:nth-child(6) {
        animation-delay: 0.5s;
    }

    .item:nth-child(7) {
        animation-delay: 0.6s;
    }

    .item:nth-child(8) {
        animation-delay: 0.7s;
    }


    @media screen and (max-width: 768px) {
        .gallary-page {
            padding: 0 0 0;

            >.Horizontal_Layout {
                align-items: flex-end;
            }

            .gallary-title {
                padding: 10px 0;
                margin: 0 24px;

                // border-bottom: 1px solid rgba(255,255,255, .6);
                .filter {
                    font-size: 14px;
                    cursor: pointer;

                    .anticon {
                        margin-left: 8px;
                    }

                }

                .tab-title {
                    img {
                        width: 60px;
                    }
                }

                .tab-title:first-child {
                    margin-right: 0.5rem;
                }

                .tab-title.active {}
            }

            .gallary-page-r {
                width: 100%;
                height: 80vh;
                padding: 0 24px;

                .gallary-page-r-banner {
                    margin: 0 0 10px;
                    height: auto;
                }

                .tab-content {
                    display: block;

                    .tab-content-imgBox {
                        grid-gap: 8px;
                        grid-template-columns: auto auto;

                        img {
                            width: 8.2rem;
                        }
                    }
                }

                .tab-content.active {
                    display: block;
                }
            }
        }

        // filter弹窗样式
        .filter-modal {
            .ant-modal-content {
                padding: 20px;
                height: 70vh;

                //overflow-y: scroll;
                .anticon-close {
                    border: 2px solid #F56702 !important;
                    border-radius: 50%;
                    color: #f56702 !important;
                    padding: 8px;
                    font-weight: bolder !important;
                    margin-right: -24px;
                    margin-top: 16px;
                }

                .ant-modal-body {
                    font-size: 16px;
                    padding: 24px 10px 24px 24px;

                    ul::-webkit-scrollbar {
                        display: none
                    }

                    ul.checkBox {
                        height: 40vh;
                        overflow-y: scroll;

                        // -ms-overflow-style: none;
                        li {
                            .gallary-filter-title {
                                padding: 10px 0;
                                border-bottom: none;

                                div {
                                    span {
                                        font-size: 12px;
                                        font-weight: bold;
                                    }
                                }

                                .arrow {
                                    font-size: 12px;
                                }

                                .arrow.hide {
                                    display: none;
                                }
                            }

                            .gallary-page-l-check {
                                display: none;

                                .ant-checkbox-wrapper {
                                    width: 100%;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    display: flex;
                                    text-align: left;
                                    margin-inline-start: 0;
                                }

                                .ant-checkbox+span {
                                    font-size: 12px;
                                    color: #000000;
                                    width: 100%;
                                }
                            }

                            .gallary-page-l-check.active {
                                display: block;
                            }
                        }

                        li:last-child .gallary-filter-title {
                            border-bottom: 0;
                        }
                    }

                    .reset-btn {
                        width: 78%;
                        text-align: center;
                        padding: 8px 0;
                        color: #000000;
                        font-size: 14px;
                        border: 1px solid rgba(0, 0, 0, .6);
                        cursor: pointer;
                        position: absolute;
                        bottom: 11%;
                    }

                    .tag-select {
                        margin-top: 10px;

                        .gallary-filter-title {
                            >div span {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }

                        .arrow.hide {
                            display: none;
                        }

                        .tag-select-radio {
                            width: 100%;

                            // 覆盖radio样式
                            .ant-radio-wrapper {
                                position: relative;
                                width: 100%;

                                .ant-radio {
                                    position: absolute;
                                    right: 0;
                                }
                            }

                            .ant-space {
                                width: 100%;
                                display: block;
                            }

                            .ant-space-item {
                                padding: 0;
                            }

                            .ant-radio-wrapper span.ant-radio+* {
                                font-size: 12px;
                                color: #000000;
                                width: 100%;
                                padding: 0;
                            }

                            .ant-radio-wrapper .ant-radio-inner {
                                background-color: transparent;
                                border-color: #d9d9d9;
                            }

                            .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
                                border-color: #F56702;
                            }

                            .ant-radio-wrapper .ant-radio-inner::after {
                                background-color: #F56702;
                            }
                        }

                        .tag-select-radio.hide {
                            display: none;
                        }
                    }
                }

                .ant-modal-footer {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;

                    .ant-btn-primary {
                        background-color: #F56702;
                    }
                }
            }

            .ant-modal-content::-webkit-scrollbar {
                width: 0 !important
            }

            // IE 10+
            .ant-modal-content {
                -ms-overflow-style: none;
            }

            // Firefox
            .ant-modal-content {
                overflow: -moz-scrollbars-none;
            }

            // ant 4.24.7
            .ant-checkbox .ant-checkbox-inner {
                border: 1px solid #F56702;
            }

            .ant-checkbox .ant-checkbox-inner:after {
                left: 3px;
            }

            .ant-checkbox-checked:after {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .filter-modal.ant-modal .ant-modal-close {
            right: 17px;
        }

        .no-more {
            font-size: 14px;
            padding: 20px 0;
        }
    }

    //蒙层
    .tab-amplification {
        // background-color: #F56702;
        background: rgba(0, 0, 0, .6) !important;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;

        .tab-amplificationdetail {
           // max-width: 20%;
            height: 100%;
            border-radius: 20px;
            line-height: 20px;

            margin: 5% auto;

            .tab-amplificationdetail-app-big {
                text-align: center;
               width: 50vh;
               height: 50vh;
               position: absolute;
               left: 50%;
               transform: translateX(-50%);
                background-color: #fff;
                border-radius: 10px;
                margin-bottom: 1rem;

                p:nth-child(2) {
                    width: 50vh;
                 
                    font-size: 0.3rem;
                    color: #000;
                    background-color: #fff;
                    margin-top: -0.15rem;
                    border-radius: 0 0 10px 10px;
                   padding: 0.4rem 0 0.3rem 0.2rem;
                  
                }
                p:nth-child(3) {
                 
                    width: 50vh;
                
                    margin-top: 7vh;
                    text-align: center;
                    font-size: 20px;
                    color: #F56702;
                    cursor: pointer;
                    border: 2px solid #F56702;
                    font-weight: bolder;
                    border-radius: 20px;
                    padding: 10px 0;
                }
            }

           

        }

        .tab-amplificationdetail-app {

            border-radius: 20px;
            line-height: 20px;

            margin: 30% 11%;

            .tab-amplificationdetail-app-big {
                background-color: #fff;
                border-radius: 10px;

                p:nth-child(2) {

                    text-align: center;

                    font-size: 20px;
                    color: #000;
                    margin: 0 0 5rem 0;

                    padding: 1rem 0;

                }
            }

            >p {
                width: 70%;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border: 2px solid #F56702;
                font-weight: bolder;
                font-size: 0.8rem;
                padding: 0.3rem 0;
                border-radius: 20px;

                color: #F56702;
            }

            p:nth-child(3) {
                cursor: pointer
            }
        }
    }

    .h-pagination {
        text-align: center;
        padding-top: 20px;
        display: flex;
        padding-bottom: 20px;
       text-align: center;
       justify-content: space-between;
       .h-pagination-web{
        display: flex;
        align-items: center;
       }
        // border-radius: 0 0 10px 10px;
        .ant-pagination {

            .ant-pagination-item {
                background-color: #240813;
                border: none;

                a {
                    color: #bbbbbb;
                    font-size: 12px;
                }

            }

            .ant-pagination-item-active {
                width: 0.3rem;
                height: 0.3rem;
                background-color: #F56702;
                border-color: #F56702;
                line-height: 0.3rem;

                a {
                    color: #fff;
                    font-size: 0.15rem;
                    font-weight: bold;

                }
            }

            .ant-pagination-item-link {
                background-color: #240813;
                border: none;

                span {
                    color: #FFFFFF;
                    font-size: 0.15rem;
                }
            }
        }
    }

    .ant-pagination-options {
        display: none !important;
    }

    .h-paginations {
        text-align: center;
        padding-top: 20px;  
        padding-bottom: 20px;
        p{
            margin-bottom: 10px;
        }
        .ant-pagination {

            .ant-pagination-item {
                background-color: #240813;
                border: none;

                a {
                    color: #bbbbbb;
                    font-size: 12px;
                }

            }

            .ant-pagination-item-active {

                background-color: #F56702;
                border-color: #F56702;

                a {
                    color: #fff;
                    font-size: 12px;
                    font-weight: bold;

                }
            }

            .ant-pagination-item-link {
                background-color: #240813;
                border: none;

                span {
                    color: #FFFFFF;
                    font-size: 12px;
                }
            }
        }
    }

    .loading-box {
        margin-top: 2rem;
    }

    .ant-spin-dot-item {
        background-color: #F56702 !important;
        font-size: 0.15rem;
    }

    .ant-spin-text {
        font-size: 12px;
        color: #F56702;
    }