.contact-page{
    height: auto;
    padding: 0 1rem;
    // background: url("../images/contact-bg.png") no-repeat;
    // background-size: 100% 100%;
    img{
        width: 100%;
    }
    .time {
        position: fixed;
        margin-top: -0.8rem;
        bottom: 0.2rem;
        p{
            font-size: 0.4rem;
            color: #ffffff;
            font-weight: bold;
            margin: 0 auto;
            text-shadow: 0 0 0 #53EDDC, 
            0 0 20px #53EDDC, 
            0 0 40px #53EDDC, 
            0 0 60px #53EDDC, 
            0 0 80px #53EDDC, 
            0 0 100px #53EDDC;
        }
    }
}


@media screen and (max-width: 768px){
    body{
        overflow-y: scroll;
    }
    body::-webkit-scrollbar { width: 0 !important }
    // IE 10+
    body { -ms-overflow-style: none; }
    // Firefox
    body { overflow: -moz-scrollbars-none; }
    .contact-page{
        height: 90vh;
        background: none;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0;
        img{
            width: 100%;
            display: block;
        }
        .time {
            display: block;
            margin-top: 30px;
            position: static;
            p{
                font-size: 14px;
                margin: 0 auto;
            }
        }
    }
}