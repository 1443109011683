.club-header{
    padding: 0 3.24rem;
    .club-header-l{
        img{
            cursor: pointer;
            width: 1.7rem;
        }
    }
    .club-header-r{
        .linkli:hover .linkt{
              
            display: block;
        }
        .linkli{
            position: relative;
        }
      
        .linkt{
            position: absolute;
            top:-0.1rem;               
            font-size: 0.1rem;
            color: #fff;
            display: none;
        }
        ul{ 
           li{
            display: inline-block;
            margin: 0.6rem 0.41rem;
          
            .link{
                font-size: 0.12rem;
                color: #FFFFFF;
                cursor: pointer;
                padding-bottom: 0.08rem;
                text-shadow: 
                0 0 0 #E4822B, 
                0 0 5px #E4822B,
                0 0 10px #E4822B,
                0 0 15px #E4822B,
                0 0 20px #E4822B,
                0 0 25px #E4822B,
                0 0 30px #E4822B,
                0 0 35px #E4822B,
                0 0 40px #E4822B,
                0 0 45px #E4822B,
                0 0 50px #E4822B;
            }
           } 
           li.active{
            .link{
                border-bottom: 1px solid #E4822B;
            } 
           }
        }
        .icon{
            margin-left: 0.41rem;
            margin-right: 0.05rem;
            img{
                width: 0.4rem;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .club-header{
        padding: 20px 24px;
        .club-header-l{
            img{
                width: 100px;
            }
            .drop-down{
                margin-right: 10px;
                .ant-space-item span{
                    font-size: 20px;
                    color: #F56702;
                }
                
            }
        }
        .club-header-r{
            .icon{
                margin-left: 10px;
                img{
                    width: 30px;
                }
            }
        }
    }
}
